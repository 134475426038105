// NotificationContext.js
import React, { createContext, useContext, useState, useCallback } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);

  const showNotification = useCallback((message, severity = 'success') => {
    const notificationId = Date.now(); // Unique ID for each notification
    setNotifications((prevNotifications) => [
      ...prevNotifications,
      { id: notificationId, message, severity }
    ]);

    // Remove the notification after the timeout
    setTimeout(() => {
      setNotifications((prevNotifications) =>
        prevNotifications.filter((notification) => notification.id !== notificationId)
      );
    }, 6000);
  }, []);

  const handleClose = useCallback((id) => {
    setNotifications((prevNotifications) =>
      prevNotifications.filter((notification) => notification.id !== id)
    );
  }, []);

  return (
    <NotificationContext.Provider value={{ showNotification }}>
      {children}
      {notifications.map(({ id, message, severity }, index) => (
        <Snackbar
          key={id}
          open={true}
          autoHideDuration={6000}
          onClose={() => handleClose(id)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          style={{
            position: 'fixed',
            bottom: `${index * 70}px`, // Adjust the bottom position for stacking
            right: '20px',
            width: 'auto',
            zIndex: 1500 + index, // Ensure the latest notification is on top and above modals
          }}
        >
          <Alert onClose={() => handleClose(id)} severity={severity} sx={{ width: '100%' }}>
            {message}
          </Alert>
        </Snackbar>
      ))}
    </NotificationContext.Provider>
  );
};

export const useNotification = () => useContext(NotificationContext);




// import React, { createContext, useContext, useState, useCallback } from 'react';
// import Snackbar from '@mui/material/Snackbar';
// import Alert from '@mui/material/Alert';

// const NotificationContext = createContext();

// export const NotificationProvider = ({ children }) => {
//   const [notifications, setNotifications] = useState([]);

//   const showNotification = useCallback((message, severity = 'success') => {
//     const notificationId = Date.now(); // Unique ID for each notification
//     setNotifications((prevNotifications) => [
//       ...prevNotifications,
//       { id: notificationId, message, severity }
//     ]);

//     // Remove the notification after the timeout
//     setTimeout(() => {
//       setNotifications((prevNotifications) =>
//         prevNotifications.filter((notification) => notification.id !== notificationId)
//       );
//     }, 6000);
//   }, []);

//   const handleClose = useCallback((id) => {
//     setNotifications((prevNotifications) =>
//       prevNotifications.filter((notification) => notification.id !== id)
//     );
//   }, []);

//   return (
//     <NotificationContext.Provider value={{ showNotification }}>
//       {children}
//       {notifications.map(({ id, message, severity }, index) => (
//         <Snackbar
//           key={id}
//           open={true}
//           autoHideDuration={6000}
//           onClose={() => handleClose(id)}
//           anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
//           style={{
//             position: 'fixed',
//             bottom: `${index * 70}px`, // Adjust the bottom position for stacking
//             right: '20px',
//             width: 'auto',
//             zIndex: 1000 + index, // Ensure the latest notification is on top
//           }}
//         >
//           <Alert onClose={() => handleClose(id)} severity={severity} sx={{ width: '100%' }}>
//             {message}
//           </Alert>
//         </Snackbar>
//       ))}
//     </NotificationContext.Provider>
//   );
// };

// export const useNotification = () => useContext(NotificationContext);