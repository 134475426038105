// src/Users.js
import React, { useEffect, useState } from 'react';
import { 
    fetchUsers, 
    updateUser, 
    createUser, 
    deleteUser 
} from './api';
import { 
    Button, 
    TextField, 
    Container, 
    Typography, 
    Table, 
    TableBody, 
    TableCell, 
    TableHead, 
    TableRow, 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogTitle, 
    DialogContentText, 
    IconButton 
} from '@mui/material';
import { 
    Edit as EditIcon, 
    Delete as DeleteIcon, 
    Add as AddIcon 
} from '@mui/icons-material';
import { useNotification } from './NotificationContext';

const Users = () => {
    const [users, setUsers] = useState([]);
    const [editingUser, setEditingUser] = useState(null);
    const [newUser, setNewUser] = useState({ username: '', email: '' });
    const [token, setToken] = useState(localStorage.getItem('token'));
    const [openAdd, setOpenAdd] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [userToDelete, setUserToDelete] = useState(null);
    const [openEdit, setOpenEdit] = useState(false);
    const { showNotification } = useNotification();

    useEffect(() => {
        fetchUsersData();
    }, []);

    const fetchUsersData = async () => {
        try {
            const response = await fetchUsers(token);
            if (response.status === 200) {
                setUsers(response.data);
                showNotification('Данные обновлены', 'success')
            } else {
                // setError('An error occurred. Please try again.');
                showNotification('An error occurred. Please try again.', 'error');
            }
        } catch (error) {
            console.error('Failed to fetch users', error);
        }
    };

    const handleEdit = (user) => {
        setEditingUser(user);
        setOpenEdit(true);
    };

    const handleCloseEdit = () => {
        setOpenEdit(false);
        setEditingUser(null);
    };

    const handleSave = async () => {
        try {
            const response = await updateUser(editingUser.id, editingUser, token);
            if (response.status === 200) {
                setOpenEdit(false);
                fetchUsersData();
                showNotification('Данные пользователя обновлены', 'success')
            } else {
                // setError('An error occurred. Please try again.');
                showNotification('An error occurred. Please try again.', 'error');
            }
        } catch (error) {
            console.error('Failed to update user', error);
        }
    };

    const handleAdd = () => {
        setOpenAdd(true);
    };

    const handleCloseAdd = () => {
        setOpenAdd(false);
        setNewUser({ username: '', email: '' });
    };

    const handleCreate = async () => {
        if (!newUser.username || !newUser.email) {
            showNotification('Требуется указать имя пользователя и адрес электронной почты.', 'warning');
            return;
        }
        try {
            const response = await createUser(newUser, token);
            if (response.status === 201) {
                setUsers([...users, response.data]);
                handleCloseAdd();
                showNotification('Пользователь добавлен.', 'success');
            }
            else if (response.status === 222) {
                handleCloseAdd();
                showNotification(response.data, 'error');
            }
            else if (response.status === 404) {
                handleCloseAdd();
                showNotification('Данные пользователя пустые.', 'information');
            }
            else {
                // setError('An error occurred. Please try again.');
                handleCloseAdd();
                showNotification('Произошла ошибка. Пожалуйста, попробуйте снова.', 'error');
            }
        } catch (error) {
            console.error('Не удалось создать пользователя.', error);
        }
    };

    const handleDelete = (user) => {
        setUserToDelete(user);
        setOpenDelete(true);
    };

    const handleCloseDelete = () => {
        setOpenDelete(false);
        setUserToDelete(null);
    };

    const handleConfirmDelete = async () => {
        try {
            const response = await deleteUser(userToDelete.id, token);
            if (response.status === 204) {
                setUsers(users.filter(user => user.id !== userToDelete.id));
                handleCloseDelete();
                showNotification('Пользователь успешно удален', 'success')
            } else {
                showNotification('An error occurred. Please try again.', 'error');
            }
        } catch (error) {
            console.error('Failed to delete user', error);
        }
    };

    return (
        <Container>
            <Typography variant="h4">Users</Typography>
            <Button variant="contained" color="primary" onClick={handleAdd} style={{ marginBottom: '20px' }}>
                Add User
            </Button>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Username</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {users.map(user => (
                        <TableRow key={user.id}>
                            <TableCell>{user.id}</TableCell>
                            <TableCell>{user.username}</TableCell>
                            <TableCell>{user.email}</TableCell>
                            <TableCell>
                                {/* <Button variant="contained" color="primary" onClick={() => handleEdit(user)}>
                                    Edit
                                </Button>
                                <Button variant="contained" color="secondary" onClick={() => handleDelete(user)} style={{ marginLeft: '10px' }}>
                                    Delete
                                </Button> */}
                                <IconButton onClick={() => handleEdit(user)}>
                                    <EditIcon />
                                </IconButton>
                                <IconButton onClick={() => handleDelete(user)} style={{ marginLeft: '10px' }}>
                                    <DeleteIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Dialog open={openAdd} onClose={handleCloseAdd}>
                <DialogTitle>Add New User</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Username"
                        value={newUser.username}
                        onChange={(e) => setNewUser({ ...newUser, username: e.target.value })}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Email"
                        value={newUser.email}
                        onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
                        fullWidth
                        margin="normal"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseAdd} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleCreate} color="primary">
                        Create
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openDelete} onClose={handleCloseDelete}>
                <DialogTitle>Delete User</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this user?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDelete} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmDelete} color="secondary">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openEdit} onClose={handleCloseEdit}>
                <DialogTitle>Edit User</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Username"
                        value={editingUser ? editingUser.username : ''}
                        onChange={(e) => setEditingUser({ ...editingUser, username: e.target.value })}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Email"
                        value={editingUser ? editingUser.email : ''}
                        onChange={(e) => setEditingUser({ ...editingUser, email: e.target.value })}
                        fullWidth
                        margin="normal"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseEdit} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSave} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default Users;