import React, { useState, useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import Login from './Login';
import Users from './Users';
import { NotificationProvider } from './NotificationContext';

function App() {
  const [token, setToken] = useState(localStorage.getItem('token'));

  useEffect(() => {
    const handleStorageChange = () => {
      setToken(localStorage.getItem('token'));
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return (
    <NotificationProvider>
      <div className="App">
        {token ? <Users /> : <Login />}
      </div>
    </NotificationProvider>
  );
}

export default App;
