// src/Login.js
import React, { useState } from 'react';
import { login } from './api';
import { Button, TextField, Container, Typography } from '@mui/material';
import { useNotification } from './NotificationContext';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [token, setToken] = useState('');
    const [error, setError] = useState('');
    const { showNotification } = useNotification();

    const handleClick = () => {
        showNotification('This is a success message', 'success');
    };

    const handleError = () => {
        showNotification('This is an error message', 'error');
    };

    const handleLogin = async () => {
        try {
            const response = await login(username, password);
            if (response.status === 200) {
                setToken(response.data.token);
                localStorage.setItem('token', response.data.token);
                // setError(''); // Clear any previous errors                
                showNotification('Token получен. Обновите страницу.', 'success')
                window.location.href = '/users';
            } else if (response.status === 400) {
                // setError('Unauthorized: Invalid username or password');
                console.error('Неверное имя пользователя или пароль.', error);
                showNotification('Неверное имя пользователя или пароль.', 'error');
            } else {
                // setError('An error occurred. Please try again.');
                showNotification('Произошла ошибка. Пожалуйста, попробуйте снова.', 'error');
            }
        } catch (error) {
            console.error('Ошибка входа', error);
            //setError('An error occurred. Please try again.');
            showNotification('Ошибка входа', 'error');
        }
    };

    return (
        <Container>
            <Typography variant="h4">Login</Typography>
            <TextField
                label="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                fullWidth
                margin="normal"
            />
            <TextField
                label="Password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                fullWidth
                margin="normal"
            />
            <Button variant="contained" color="primary" onClick={handleLogin}>
                Login
            </Button>
            {/* {token && <Typography>Token: {token}</Typography>}
            {error && <Typography color="error">{error}</Typography>} */}

            {/* <button onClick={handleClick}>Show Success</button>
            <button onClick={handleError}>Show Error</button> */}
        </Container>
    );
};

export default Login;