// src/api.js
import axios from 'axios';

const API_URL = '/api';

const api = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json'
    }
});

// Интерцептор для обработки ошибок
api.interceptors.response.use(
    response => response,
    error => {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
            window.location.href = '/login'; // Перенаправление на страницу логина
        }
        return Promise.reject(error);
    }
);

export const login = (username, password) => {
    return api.post('/auth/login', { username, password });
};

export const fetchUsers = (token) => {
    return api.get('/users', {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
};

export const updateUser = (id, user, token) => {
    return api.put(`/users/${id}`, user, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
};

export const createUser = (user, token) => {
    return api.post('/users', user, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
};

export const deleteUser = (id, token) => {
    return api.delete(`/users/${id}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
};
